import * as R from 'ramda'
import {
  Criticality,
  EquipmentCategory,
  EquipmentGroupStId,
  InteractionScope,
  Module,
  Offer,
  OfferingInteractionQuestion,
  OfferingQuestion,
  OfferingQuestions,
  SapKey,
  Scope,
  Selections,
  Selection
} from '../../../../types/types'
import * as deps from '../dependencies/dependencies'
import { Dependency } from '../dependencies/types'
import {
  Flexible,
  Offering,
  CriticalityPath,
  QuickTenderWithRatings,
  QuickTender,
  match,
  ValueAddedServicesAddendum,
  Interaction,
  ResiFlowInteraction,
  GSMCampaign // TODO: Remove as part of GSM Clean up
} from './types'
import { Option, some, none } from 'fp-ts/lib/Option'
import * as Flex from './flexible'
import * as QTWithRatings from './quick-tender-with-ratings'
import * as QT from './quick-tender'
import * as VAS from './value-added-services'
import { getGroupOfferingQuestionsByOffering } from './questions'
import { ValidateGroup, StateChange, ValidateAll } from './state-change'
import { isServiceAvailable } from '../../../../common/common-utils'
import { dxServices } from '../../../../common/equipment-utils'
import { checkForDxGroup } from '../../../../client/js/components/OfferWizard/Offering/components/dx-labelling-utils'

const MODULES: Module[] = [
  'basics',
  'technical',
  'performance',
  'value_added_services',
  'extra_services',
  'resiflow',
  'gsm_campaign', // TODO: Remove as part of GSM Clean up
  'people_flow'
]

export interface Answer {
  readonly groupId: EquipmentGroupStId
  readonly criticality: Criticality
  readonly equipmentCategory: EquipmentCategory
  readonly choice: string | null
  readonly module: Module
  readonly question: {
    key: SapKey
    type: string
    optional: boolean
  }
}

export interface InteractionAnswer {
  readonly choice: string | null
  readonly question: {
    key: SapKey
    type: string
    optional: boolean
  }
}

function updateSelectionsWithAnswer(
  groupQuestions: OfferingQuestion[],
  answer: Answer,
  currentAnswers: Selections,
  offer: Offer,
  groupId: EquipmentGroupStId,
  criticality: Criticality
): Selections {
  const { question: q, choice } = answer
  const currentAnswer = R.propOr(null, q.key, currentAnswers)
  const newAnswer = getAnswer({
    type: q.type,
    currentAnswer,
    choice,
    optional: q.optional
  })
  const initAnswers = R.assoc(q.key, newAnswer, currentAnswers)
  return deps.applySetterDependencies(groupQuestions, initAnswers, offer, groupId, criticality)
}

export function answerGroupQuestion(
  questions: OfferingQuestions,
  offering: Offering,
  offer: Offer,
  answer: Answer
): StateChange {
  const { groupId, equipmentCategory, criticality } = answer
  const questionsByCategory = getGroupOfferingQuestionsByOffering(questions, offering, offer.isGSMOffer) // TODO: Remove as part of GSM Clean up
  const questionsForCategory = questionsByCategory[equipmentCategory]
  switch (offering._tag) {
    case 'Flexible':
      return new ValidateGroup(
        answer,
        answer.module,
        Flex.updateSelections(
          (xs: Selections) => updateSelectionsWithAnswer(questionsForCategory, answer, xs, offer, groupId, criticality),
          { groupId, equipmentCategory, criticality },
          offering
        )
      )
    case 'QuickTender':
      return new ValidateGroup(
        answer,
        answer.module,
        QT.updateSelections(
          (xs: Selections) => updateSelectionsWithAnswer(questionsForCategory, answer, xs, offer, groupId, criticality),
          answer.groupId,
          offering
        )
      )
    case 'QuickTenderWithRatings':
      return new ValidateGroup(
        answer,
        answer.module,
        QTWithRatings.updateSelections(
          (xs: Selections) => updateSelectionsWithAnswer(questionsForCategory, answer, xs, offer, groupId, criticality),
          { groupId, equipmentCategory: 'elevator', criticality },
          offering
        )
      )
    case 'QuickTenderScopePending':
      return new ValidateGroup(answer, answer.module, offering)
    case 'ValueAddedServicesAddendum':
      return new ValidateGroup(
        answer,
        answer.module,
        VAS.updateSelections(
          (xs: Selections) => updateSelectionsWithAnswer(questionsForCategory, answer, xs, offer, groupId, criticality),
          answer.groupId,
          offering
        )
      )
    // TODO: Remove as part of GSM Clean up
    case 'gsm_campaign':
      return new ValidateGroup(
        answer,
        answer.module,
        VAS.updateGSMSelections(
          (xs: Selections) => updateSelectionsWithAnswer(questionsForCategory, answer, xs, offer, groupId, criticality),
          answer.groupId,
          offering
        )
      )
    case 'NoRatings':
      throw new Error('Invalid offering')
  }
}

export function answerInteractionQuestion(
  questions: OfferingInteractionQuestion[],
  offering: Offering,
  answer: InteractionAnswer,
  offer: Offer
): StateChange {
  const {
    question: { key, type, optional },
    choice
  } = answer
  function updateInteractionAnswers(qs: OfferingInteractionQuestion[], answers: Selections): Selections {
    const newAnswer = getAnswer({
      type,
      currentAnswer: R.propOr(null, key, answers),
      choice,
      optional
    })
    return deps.applySetterDependencies(qs, R.assoc(key, newAnswer, answers), offer, null, null)
  }
  return new ValidateAll(
    match(offering, {
      NoRatings: () => offering,
      Flexible: (groups, offeringInteraction) => {
        if (offeringInteraction) {
          return new Flexible(groups, {
            selections: updateInteractionAnswers(questions, offeringInteraction.selections),
            scope: offeringInteraction.scope
          } as Interaction)
        }
        return new Flexible(groups, null as ResiFlowInteraction)
      },
      QuickTenderWithRatings: (groups, scope, interactionSelections, selectedInteractionRating) =>
        new QuickTenderWithRatings(
          groups,
          scope,
          updateInteractionAnswers(questions, interactionSelections),
          selectedInteractionRating
        ),
      QuickTender: (groups, scope, interactionSelections) =>
        new QuickTender(groups, scope, updateInteractionAnswers(questions, interactionSelections)),
      QuickTenderScopePending: () => offering,
      ValueAddedServices: (groups, interactionSelections) => {
        if (offer.isGSMOffer) {
          return new GSMCampaign(groups, updateInteractionAnswers(questions, interactionSelections))
        }
        return new ValueAddedServicesAddendum(groups, updateInteractionAnswers(questions, interactionSelections))
      },
      GSMCampaign: (groups, interactionSelections) =>
        new GSMCampaign(groups, updateInteractionAnswers(questions, interactionSelections)) // TODO: Remove as part of GSM Clean up
    })
  )
}

function getAnswer(spec: { type: string; currentAnswer: any; choice: string | null; optional: boolean }) {
  const { type, currentAnswer, choice, optional } = spec

  function toggleMultiValue(value: string, currentValues: string[] | null) {
    if (currentValues === null) {
      return [value]
    } else if (R.contains(value, currentValues)) {
      const xs = R.without([value], currentValues)
      return xs.length === 0 ? (optional ? null : ['0']) : xs
    } else {
      return R.append(value, currentValues).filter(v => v !== '0')
    }
  }

  function toggleOptionalValue(value: string | null, currentValue: string | null) {
    if (value === currentValue) {
      return null
    } else {
      return value
    }
  }

  if (type === 'multiple' && choice !== null) {
    return toggleMultiValue(choice, currentAnswer)
  } else if (optional) {
    return toggleOptionalValue(choice, currentAnswer)
  } else {
    return choice
  }
}

interface Question {
  key: SapKey
  default_values: PRecord<Scope, string | string[]>
  choices: string[]
  optional: boolean
  dependencies: Dependency[]
}

export function getDefaultsForScope(scope: Scope, initialSelections: ReadonlyArray<Question>): Selections {
  return initialSelections.reduce((acc, question) => {
    const { key, default_values } = question
    if (default_values.hasOwnProperty(scope)) {
      return R.assoc(key, default_values[scope], acc)
    } else {
      // we allow optional questions to not have defaults, in which case we
      // use null as the default value
      if (question.optional) {
        return R.assoc(key, null, acc)
      } else {
        throw new Error(
          'No default choice set for: ' + key + ' in scope ' + scope + '. Please check your MBS configuration'
        )
      }
    }
  }, {} as Selections)
}

export function getInitialAnswersForModuleScopes(
  questions: OfferingQuestion[],
  offer: Offer,
  groupId: EquipmentGroupStId,
  criticality: Criticality,
  moduleScopes: Array<{ moduleName: Module; scope: Scope }>
): Selections {
  const answers = R.reduce(
    (acc, { moduleName, scope }) =>
      R.merge(
        acc,
        getDefaultsForScope(
          scope,
          questions.filter(question => R.prop('module', question) === moduleName)
        )
      ),
    {},
    moduleScopes
  )
  return deps.applySetterDependencies(questions, answers, offer, groupId, criticality)
}

export function getInitialAnswersForScope(
  questions: OfferingQuestion[],
  scope: Scope,
  modules: Module[],
  currentAnswers: Selections,
  offer: Offer,
  groupId: EquipmentGroupStId,
  criticality: Criticality | null,
  isDXEnabled: boolean
): Selections {
  const answers = R.reduce(
    (acc, moduleName) =>
      R.merge(
        acc,
        getDefaultsForScope(
          scope,
          questions.filter(question => R.prop('module', question) === moduleName)
        )
      ),
    currentAnswers,
    modules
  )

  const isDxGroup = offer.dxGrpId === groupId || checkForDxGroup(offer, groupId) || false
  const servicesForPreselectionInDx: SapKey[] =
    offer.opportunity.opportunityCategory === 'Value Added Service' ? [dxServices[0]] : dxServices

  Object.assign(
    answers,
    isDXEnabled
      ? servicesForPreselectionInDx.reduce((answersObj: Selections, service) => {
          if (answers.KCSM_24_7_CONNECT !== '0' && isDxGroup) {
            service === 'KCSM_CARBON_NEUTRAL'
              ? (answersObj = { ...answersObj, KCSM_CARBON_NEUTRAL: '1' })
              : answers.KCSM_24_7_CONNECT === '2' && isServiceAvailable(service, '0', questions)
              ? (answersObj = { ...answersObj, KCSM_REMOTE_INTERVENTIONS: '0' })
              : isServiceAvailable(service, '1', questions)
              ? (answersObj = { ...answersObj, KCSM_REMOTE_INTERVENTIONS: '1' })
              : (answersObj = { ...answersObj })
          } else {
            service === 'KCSM_REMOTE_INTERVENTIONS' && !isDxGroup && isServiceAvailable(service, '0', questions)
              ? (answersObj = { ...answersObj, KCSM_REMOTE_INTERVENTIONS: '0' })
              : (answersObj = { ...answersObj })
          }
          return answersObj
        }, {})
      : {}
  )
  return deps.applySetterDependencies(questions, answers, offer, groupId, criticality)
}

export const getInitialAnswersForAllModules = (
  questions: OfferingQuestion[],
  scope: Scope,
  offer: Offer,
  groupId: EquipmentGroupStId,
  criticality: Criticality | null,
  isDXEnabled: boolean
) => getInitialAnswersForScope(questions, scope, MODULES, {}, offer, groupId, criticality, isDXEnabled)

export const getInitialAnswersForInteraction = (
  questions: OfferingInteractionQuestion[],
  scope: InteractionScope,
  offer: Offer
): Selections =>
  deps.applySetterDependencies(
    questions,
    getDefaultsForScope(
      scope,
      R.map(q => R.pick(['key', 'default_values', 'choices', 'optional', 'dependencies'], q), questions)
    ),
    offer,
    null,
    null
  )

export function selections(path: CriticalityPath, offering: Offering): Option<Selections> {
  switch (offering._tag) {
    case 'Flexible':
      return Flex.selections(path, offering)
    case 'QuickTender':
    case 'ValueAddedServicesAddendum':
    case 'gsm_campaign': // TODO: Remove as part of GSM Clean up
      const group = offering.groups[path.groupId]
      return group ? some(group.selections) : none
    case 'QuickTenderWithRatings':
      return QTWithRatings.selections(path, offering)
    case 'QuickTenderScopePending':
    case 'NoRatings':
      return none
  }
}

// These values commonly indicate unchecked selection.
// Hopefully there isn't a compass direction selection with values [0, N, E, W, S] or something
const probablyUnchecked = ['0', '00', 'N', 'NA', 'FALSE']
export const isUncheckedString = (s: string | undefined | null) => {
  const sUpper = (s || '').toUpperCase()
  return !sUpper || probablyUnchecked.includes(sUpper)
}

// Tests if selection logically equals unchecked/empty/none selection
export function isSelectionUnchecked(selection: Selection | string | string[]) {
  if (typeof selection === 'string') {
    return isUncheckedString(selection)
  } else if (Array.isArray(selection)) {
    return selection.length < 2 && isUncheckedString(selection[0])
  } else {
    return true
  }
}
